import _react from "react";
import _propTypes from "prop-types";
var exports = {};

function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}

var React = _react;

var React__default = _interopDefault(React);

var PropTypes = _interopDefault(_propTypes);

var classCallCheck = function (instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
};

var createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();

var _extends = Object.assign || function (target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i];

    for (var key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        target[key] = source[key];
      }
    }
  }

  return target;
};

var inherits = function (subClass, superClass) {
  if (typeof superClass !== "function" && superClass !== null) {
    throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
  }

  subClass.prototype = Object.create(superClass && superClass.prototype, {
    constructor: {
      value: subClass,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });
  if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
};

var objectWithoutProperties = function (obj, keys) {
  var target = {};

  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }

  return target;
};

var possibleConstructorReturn = function (self, call) {
  if (!self) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return call && (typeof call === "object" || typeof call === "function") ? call : self;
};
/**
 * @class BlockImage
 *
 * Component that displays a div with background-image instead of an img for
 * more control. Also allows for specifying an optional fallback image and
 * loading component.
 */


var BlockImage = function (_PureComponent) {
  inherits(BlockImage, _PureComponent);

  function BlockImage() {
    var _ref;

    var _temp, _this, _ret;

    classCallCheck(this, BlockImage);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = possibleConstructorReturn(this, (_ref = BlockImage.__proto__ || Object.getPrototypeOf(BlockImage)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      status: "loading"
    }, _this._isMounted = false, _temp), possibleConstructorReturn(_this, _ret);
  }

  createClass(BlockImage, [{
    key: "componentWillMount",
    value: function componentWillMount() {
      this._isMounted = true;

      this._reload(this.props);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this._isMounted = false;
    }
  }, {
    key: "componentWillReceiveProps",
    value: function componentWillReceiveProps(props) {
      if (this.props.src !== props.src || this.props.fallback !== props.fallback && this.state.status === "error") {
        this._reload(props);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _props = this.props,
          src = _props.src,
          fallback = _props.fallback,
          showPreview = _props.showPreview,
          loader = _props.loader,
          backgroundPosition = _props.backgroundPosition,
          backgroundRepeat = _props.backgroundRepeat,
          backgroundSize = _props.backgroundSize,
          children = _props.children,
          style = _props.style,
          rest = objectWithoutProperties(_props, ["src", "fallback", "showPreview", "loader", "backgroundPosition", "backgroundRepeat", "backgroundSize", "children", "style"]);
      var status = this.state.status;
      var bgImageStyles = {};
      var loadingAndShowPreview = status === "loading" && showPreview;
      var url = src || fallback;

      if (fallback && (status === "error" || loadingAndShowPreview)) {
        url = fallback;
      }

      if (url) {
        var backgroundImage = "url(" + url + ")";
        bgImageStyles.backgroundImage = backgroundImage;
        bgImageStyles.backgroundPosition = backgroundPosition;
        bgImageStyles.backgroundRepeat = backgroundRepeat;
        bgImageStyles.backgroundSize = backgroundSize;
      }

      var rootStyle = Object.assign(bgImageStyles, style);
      return React__default.createElement("div", _extends({
        style: rootStyle
      }, rest), children, loadingAndShowPreview && loader);
    }
  }, {
    key: "_reload",
    value: function _reload(props) {
      var _this2 = this;

      var src = props.src;

      if (!src) {
        this.setState({
          status: "error"
        });
      } else {
        var img = new Image();

        img.onload = function () {
          if (_this2._isMounted) {
            _this2.setState({
              status: "success"
            });
          }
        };

        img.onerror = img.onabort = function (event) {
          if (_this2._isMounted) {
            _this2.setState({
              status: "error"
            });
          }
        };

        this.setState({
          status: "loading"
        });
        img.src = src;
      }
    }
  }]);
  return BlockImage;
}(React.PureComponent);

BlockImage.propTypes = {
  // preferred image source url
  src: PropTypes.string,
  // fallback image source url
  fallback: PropTypes.string,
  // optional children
  children: PropTypes.node,
  // whether or not to show fallback while preferred src is loading
  showPreview: PropTypes.bool,
  // node to show while image is loading
  loader: PropTypes.node,
  // optionally control css background-size
  backgroundSize: PropTypes.string,
  // optionally control css background-position
  backgroundPosition: PropTypes.string,
  // optionally control css background-repeat
  backgroundRepeat: PropTypes.string,
  // optional style override
  style: PropTypes.object
};
BlockImage.defaultProps = {
  showPreview: false,
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover"
};
exports = BlockImage;
export default exports;